import React from 'react';
import { graphql } from 'gatsby';
import { NavyYardLocationPageQuery } from '../../../../graphql-types';

import LocationTemplate from '../../../components/locations/LocationTemplate';

export const pageQuery = graphql`
  query NavyYardLocationPage {
    allContentfulPagesLocationTemplate(
      filter: { pageId: { eq: "location_navy-yard" } }
    ) {
      nodes {
        locationName
        locationRegion
        seoTitle
        seoDescription
        isComingSoon
        displayAppointmentsCalendar
        displayOfferBanner
        offerBannerText {
          offerBannerText
        }
        couponCode
        addressLine1
        addressLine2
        phoneNumber
        emailAddress
        hoursLine1
        hoursLine2
        hoursLine3
        descriptionText1 {
          descriptionText1
        }
        descriptionText2 {
          descriptionText2
        }
        transportationTrain {
          transportationTrain
        }
        transportationBus {
          transportationBus
        }
        parking {
          parking
        }
        services
        locationTeam {
          name
          urlSlug
          avatar {
            fluid(maxWidth: 400, quality: 100) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
        imagesVertical {
          description
          fixed(quality: 100, width: 400, height: 640) {
            ...GatsbyContentfulFixed_withWebp
          }
          fluid(quality: 100, maxWidth: 312, maxHeight: 312) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        imagesHorizontal {
          description
          fixed(quality: 100, width: 1000, height: 640) {
            ...GatsbyContentfulFixed_withWebp
          }
          fluid(quality: 100, maxWidth: 312, maxHeight: 312) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        schemaMarkup {
          _context
          _type
          name
          image
          _id
          url
          telephone
          address {
            _type
            streetAddress
            addressLocality
            addressRegion
            postalCode
            addressCountry
          }
          geo {
            _type
            latitude
            longitude
          }
          openingHoursSpecification {
            _type
            dayOfWeek
            opens
            closes
          }
        }
        iframeUrl {
          iframeUrl
        }
        googleBusinessUrl
        breadcrumbSchema {
          _context
          _type
          itemListElement {
            _type
            position
            name
            item
          }
        }
      }
    }
  }
`;
interface Props {
  data: NavyYardLocationPageQuery;
}

const NavyYardLocation = ({ data }: Props) => {
  const {
    locationName,
    locationRegion,
    seoTitle,
    seoDescription,
    isComingSoon,
    displayAppointmentsCalendar,
    displayOfferBanner,
    offerBannerText,
    couponCode,
    addressLine1,
    addressLine2,
    phoneNumber,
    emailAddress,
    hoursLine1,
    hoursLine2,
    hoursLine3,
    descriptionText1,
    descriptionText2,
    transportationTrain,
    transportationBus,
    parking,
    services,
    locationTeam,
    imagesVertical,
    imagesHorizontal,
    schemaMarkup,
    iframeUrl,
    googleBusinessUrl,
    breadcrumbSchema,
  } = data.allContentfulPagesLocationTemplate.nodes[0];
  return (
    <LocationTemplate
      seo={{
        title: seoTitle,
        description: seoDescription,
        schema: schemaMarkup,
        breadcrumbSchema,
      }}
      offerBanner={{
        display: displayOfferBanner,
        text: offerBannerText.offerBannerText,
        couponCode,
      }}
      carouselDesktop={{
        images: [
          {
            fixed: imagesVertical[0].fixed,
            altText: imagesVertical[0].description,
          },
          {
            fixed: imagesHorizontal[0].fixed,
            altText: imagesHorizontal[0].description,
          },
          {
            fixed: imagesVertical[1].fixed,
            altText: imagesVertical[1].description,
          },
          {
            fixed: imagesHorizontal[1].fixed,
            altText: imagesHorizontal[1].description,
          },
          {
            fixed: imagesVertical[2].fixed,
            altText: imagesVertical[2].description,
          },
          {
            fixed: imagesHorizontal[2].fixed,
            altText: imagesHorizontal[2].description,
          },
          {
            fixed: imagesVertical[3].fixed,
            altText: imagesVertical[3].description,
          },
          {
            fixed: imagesHorizontal[3].fixed,
            altText: imagesHorizontal[3].description,
          },
        ],
      }}
      carouselMobile={{
        images: [
          {
            fluid: imagesVertical[0].fluid,
            altText: imagesVertical[0].description,
          },
          {
            fluid: imagesHorizontal[0].fluid,
            altText: imagesHorizontal[0].description,
          },
          {
            fluid: imagesVertical[1].fluid,
            altText: imagesVertical[1].description,
          },
          {
            fluid: imagesHorizontal[1].fluid,
            altText: imagesHorizontal[1].description,
          },
          {
            fluid: imagesVertical[2].fluid,
            altText: imagesVertical[2].description,
          },
          {
            fluid: imagesHorizontal[2].fluid,
            altText: imagesHorizontal[2].description,
          },
          {
            fluid: imagesVertical[3].fluid,
            altText: imagesVertical[3].description,
          },
          {
            fluid: imagesHorizontal[3].fluid,
            altText: imagesHorizontal[3].description,
          },
        ],
      }}
      location={{
        name: locationName,
        region: 'washington-dc',
        isComingSoon,
        address1: addressLine1,
        address2: addressLine2,
        phoneNumber,
        email: emailAddress,
        hours1: hoursLine1,
        hours2: hoursLine2,
        hours3: hoursLine3,
        descriptionText1: descriptionText1.descriptionText1,
        descriptionText2: descriptionText2.descriptionText2,
        transportation1: transportationTrain.transportationTrain,
        parking: parking.parking,
        services,
        team: locationTeam,
        iframeUrl: iframeUrl.iframeUrl,
        googleBusinessUrl,
      }}
      appointments={{
        locationId: 12,
        displayCalendar: displayAppointmentsCalendar,
      }}
    />
  );
};

export default NavyYardLocation;
